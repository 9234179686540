import { Title as HomeTitle, Path as HomePath } from '../components/screens/ProductsScreen';
import { Title as ContactTitle, Path as ContactPath } from "../components/screens/ContactScreen";
import { Title as AboutMeTitle, Path as AboutMePath } from "../components/screens/AboutMeScreen";
import { 
    Subtitle1 as JestemKobietaTitle,
    Path as BuyPath } from "../components/screens/BuyScreen";
import { GetIdBybCategoryTitle } from "../components/common/BuyItems";
import { Path as TusPath, Title as TusTitle } from "../components/screens/TusScreen";
import { Path as GroupsPath, Title as GroupsTitle } from "../components/screens/GoupsScreen";

export const appBaseRouteKey = "";

export enum configSectionType {
    'divider',
    'link'
}

export type configSection = {
    title: string;
    api: string;
    type: configSectionType;
}

export function GetFullPathTo(title: string): string{
    const result = OrderedSectionsConfiguration.findIndex((p: configSection) => p.title === title);
    return OrderedSectionsConfiguration[result].api;
}

export const OrderedSectionsConfiguration: Array<configSection> = [
    {
        title: JestemKobietaTitle,
        api: appBaseRouteKey + BuyPath + "/" + GetIdBybCategoryTitle(JestemKobietaTitle),
        type: configSectionType.link
    },
    {
        title: TusTitle,
        api: appBaseRouteKey + TusPath,
        type: configSectionType.link
    },
    {
        title: GroupsTitle,
        api: appBaseRouteKey + GroupsPath,
        type: configSectionType.link
    },
    {
        title: "",
        api: "",
        type: configSectionType.divider
    },
    {
        title: HomeTitle,
        api: appBaseRouteKey + HomePath,
        type: configSectionType.link
    },
    {
        title: ContactTitle,
        api: appBaseRouteKey + ContactPath,
        type: configSectionType.link
    },
    {
        title: AboutMeTitle,
        api: appBaseRouteKey + AboutMePath,
        type: configSectionType.link
    }
];